.logo-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logo-img {
    position: relative;
    width: 15%;
}

.logo-text {
    position: relative;
    width: fit-content;
    font-size: 4.5vh;
    font-weight: bold;
    color: #fff;
    text-align: center;
    text-shadow: 0 0 1vh #000;
}