.app-container {
  width: 100%;
  height: 100%;
}

.app-background-color {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background-color: #0B1D26;
  z-index: -100;
}