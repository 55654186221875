.homePage-container {
    width: 100%;
    height: 100%;
}

.logo {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.form-page {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    padding: 1vh;
}

.email-input {
    width: 40vh;
    height: 5vh;
    border-radius: 0.5vh;
    border: none;
    outline: none;
    text-align: center;
    font-size: 2.5vh;
    margin: 2vh;
    box-shadow: 0 0 0.5vh 0.1vh #0B1D26;
}

.form-btn-area {
    width: 40vh;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0 2vh;
}

.form-btn {
    width: 100%;
    height: 100%;
    border-radius: 0.5vh;
    border: none;
    outline: none;
    background-image: linear-gradient(0deg, #214628 0%, #1a2a38 50%);
    color: white;
    font-size: 2.5vh;
    font-weight: bold;
    cursor: pointer;
}

.form-btn.small {
    width: 20%;
    margin-right: 2vh;
    font-size: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 0.5vh;
}

.form-btn:hover {
    box-shadow: 0 0 0.5vh 0.1vh #ffffff;
}

.form-btn:active {
    scale: 0.95;
}

.name-input {
    width: 40vh;
    height: 5vh;
    border-radius: 0.5vh;
    border: none;
    outline: none;
    text-align: center;
    font-size: 2.5vh;
    margin: 2vh;
    box-shadow: 0 0 0.5vh 0.1vh #0B1D26;
}

.inquiry-input {
    width: 40vh;
    height: 15vh;
    border-radius: 0.5vh;
    border: none;
    outline: none;
    text-align: center;
    font-size: 2.5vh;
    margin-bottom: 2vh;
    box-shadow: 0 0 0.5vh 0.1vh #0B1D26;
}

.form-response-error {
    width: 40vh;
}

.form-response-error-text {
    color: red;
    font-size: 2vh;
    font-weight: bold;
    text-align: center;
    text-shadow: 0 0 1vh #000000;
    margin: 1vh;
}

.inquiry-success-response-page {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    color: white;
    text-shadow: 0 0 1vh #000000;
}